import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import axios from "axios";
import {
  DashboardInterface,
  InviteUserInterface,
  InvitedUserInterface,
  ParticipantInterface,
  UserFilterInterface,
  UserInterface,
} from "../interface/UserInterface";
import { AuthService } from "./authService";
import { PagingInterface } from "../interface/PaginationInterface";

@injectable()
export class UserService {
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(AuthService) public authService: AuthService,
  ) {}

  public async updateProfile(updatedData: {
    first_name: string;
    last_name: string;
    email: string;
  }): Promise<UserInterface | void> {
    return axios
      .put<UserInterface>(this.apiCallService.apiUrl("users"), updatedData)
      .then(() => this.authService.fetchUser());
  }

  public async updatePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<UserInterface> {
    return axios
      .put<UserInterface>(this.apiCallService.apiUrl("users/password"), {
        old_password: oldPassword,
        password: newPassword,
      })
      .then((result) => result.data);
  }

  public async getInvitedUser(
    userFilters: Partial<UserFilterInterface>,
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
  ): Promise<PagingInterface<InvitedUserInterface>> {
    return axios
      .get<
        PagingInterface<InvitedUserInterface>
      >(this.apiCallService.apiUrl("users/invitations", { ...userFilters, ...pageConfig }))
      .then((result) => result.data);
  }

  public async addUser(
    userData: InviteUserInterface,
  ): Promise<InvitedUserInterface> {
    return axios
      .post<InvitedUserInterface>(
        this.apiCallService.apiUrl("users/invitations"),
        {
          ...userData,
        },
      )
      .then((result) => result.data);
  }

  public async getUserWithRole(
    role: string,
    all: boolean = false,
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
    email: string = "",
    signal?: AbortSignal,
  ): Promise<PagingInterface<UserInterface>> {
    return axios
      .get<PagingInterface<UserInterface>>(
        this.apiCallService.apiUrl("users/index"),
        {
          signal,
          params: {
            role,
            all,
            pageConfig,
            email,
          },
        },
      )
      .then((result) => result.data);
  }
  public async sendInvite(id: number): Promise<{ status: "ok" }> {
    return axios
      .post<{
        status: "ok";
      }>(this.apiCallService.apiUrl(`users/invitations/${id}/send`))
      .then((result) => result.data);
  }
  public async deleteInvitation(id: number): Promise<{ status: string }> {
    return axios
      .delete<{
        status: string;
      }>(this.apiCallService.apiUrl(`users/invitations/${id}`))
      .then((result) => result.data);
  }

  public async editInvitation(
    user: InviteUserInterface,
    id: number,
  ): Promise<InvitedUserInterface> {
    return axios
      .put(this.apiCallService.apiUrl(`users/invitations/${id}`), {
        ...user,
      })
      .then((result) => result.data);
  }

  public async feedbackDurationlleft(
    id: number,
  ): Promise<ParticipantInterface> {
    return axios
      .get<ParticipantInterface>(
        this.apiCallService.apiUrl(`users/invitations/${id}/dashboard`),
      )
      .then((result) => result.data);
  }

  public async getCurrentInvitation(id: number): Promise<InvitedUserInterface> {
    return axios
      .get<InvitedUserInterface>(
        this.apiCallService.apiUrl(`users/invitations/${id}`),
      )
      .then((result) => result.data);
  }

  public async getDashboardData(year: number): Promise<DashboardInterface> {
    return axios
      .get<DashboardInterface>(
        this.apiCallService.apiUrl(`dashboard`, { year }),
      )
      .then((result) => result.data);
  }

  public async finsihFeedback(id: number): Promise<InvitedUserInterface> {
    return axios
      .post<InvitedUserInterface>(
        this.apiCallService.apiUrl(`users/invitations/${id}/feedback-done`),
      )
      .then((result) => result.data);
  }
}
