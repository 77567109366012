<script setup lang="ts">
const props = defineProps<{
  title: string;
  value: number | string;
  icon: string;
}>();
</script>

<template>
  <div class="stats-card px-3 py-2 d-flex flex-column justify-content-center">
    <div class="d-flex align-items-center justify-content-between gap-4">
      <span class="fw-medium title">{{ props.title }}</span>
      <i class="bi" :class="props.icon"></i>
    </div>
    <span class="fw-semibold fs-2 value">{{ props.value }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import "../theme/variables";

.stats-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  max-width: 250px;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  justify-content: space-between;
  i {
    font-size: 25px;
    color: $blue;
    position: relative;
  }
  .title {
    font-size: 14px;
  }
}
.value {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
