<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import router from "../../routes";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import RaterQuestionComponent from "./RaterQuestionComponent.vue";
import {
  QuestionCategories,
  QuestionInputTypes,
  QuestionInterface,
  QuestionSubCategories,
} from "../../interface/QuestionInterface";
import { appContainer } from "../../container";
import { ManageQuestionService } from "../../services/manageQuestionService";

const currentInvitationStore = CurrentInvitationStore();

const questionService = appContainer.resolve(ManageQuestionService);

const ratingQuestion1 = ref<QuestionInterface>({
  id: 1,
  created_at: "2024-07-29 10:09:08",
  updated_at: "2024-07-29 10:09:09",
  question_text: "This is a sample rating type question",
  question_category: QuestionCategories.Lead,
  question_subcategory: QuestionSubCategories.SetDirection,
  input_type: QuestionInputTypes.Rating,
  company_id: null,
});

const paragraphQuestion1 = ref<QuestionInterface>({
  id: 1,
  created_at: "2024-07-29 10:09:10",
  updated_at: "2024-07-29 10:09:11",
  question_text: "This is a sample paragraph type question",
  question_category: null,
  question_subcategory: null,
  input_type: QuestionInputTypes.Paragraph,
  company_id: null,
});

const feedbackStatus = ref(false);
const isLoading = ref(true);

onBeforeMount(async () => {
  currentInvitationStore.getCurrentInvitation();
  await getCurrentPage();
});

async function getCurrentPage() {
  questionService
    .getCurrentPage(currentInvitationStore.currentInvitation!.id)
    .then((result) => {
      feedbackStatus.value = !(
        result.question.question_subcategory ===
        QuestionSubCategories.SetDirection
      );
      isLoading.value = false;
    });
}
</script>
<template>
  <div class="page-wrapper position-relative h-100" v-if="!isLoading">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h2 class="fw-medium fs-4 m-0">Sample Questions</h2>
      <button
        class="btn btn-secondary text-white ms-auto d-block start-btn"
        @click="router.push('/feedback')"
      >
        {{ feedbackStatus ? "Continue" : "Start" }} Assessment
      </button>
    </div>

    <div class="question-wrapper mt-4">
      <p class="mb-2 pb-4">
        Each screen contains four questions on a specific topic. You must answer
        all four questions before proceeding to the next screen. At the end,
        there will be descriptive questions where you can share your feedback.
      </p>
      <p class="mb-4 fw-bold">Rating Type Questions</p>
      <RaterQuestionComponent
        :question="ratingQuestion1"
        :index="1"
        :preview="true"
      />
      <p class="fw-bold mb-0 mt-4">Written Comments</p>
      <RaterQuestionComponent
        :question="paragraphQuestion1"
        :index="1"
        :preview="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rating-scale {
  width: fit-content;
  button {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    width: 80px;
  }
  gap: 16px;
}
.rate-btn {
  max-width: 170px;
  span {
    font-size: 13px;
  }
}
.text-area {
  max-width: 900px;
  textarea {
    height: 100px;
  }
}
.page-footer {
  bottom: 40px;
}
@media (max-width: 575px) {
  .start-btn {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
