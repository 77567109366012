import { UserInterface } from "./UserInterface";

export interface QuestionInterface {
  id: number;
  created_at: string | Date;
  updated_at: string | Date;
  question_text: string;
  question_category: QuestionCategories | null;
  input_type: QuestionInputTypes;
  question_subcategory: QuestionSubCategories | null;
  company_id: number | null;
}

export interface PostQuestionInterFace {
  question_text: string;
  question_category: QuestionCategories | null;
  input_type: QuestionInputTypes | null;
  question_subcategory: QuestionSubCategories | null;
}

export enum QuestionCategories {
  Lead = "Lead",
  Develop = "Develop",
  Care = "Care",
  EQSelf = "EQ Self",
  EQOthers = "EQ Others",
}

export enum QuestionSubCategories {
  SetDirection = "Set Direction",
  Align = "Align",
  Motivate = "Motivate",
  Manage = "Manage",
  Discover = "Discover",
  Teach = "Teach",
  Model = "Model",
  Coach = "Coach",
  Know = "Know",
  Connect = "Connect",
  Provide = "Provide",
  Protect = "Protect",
  SelfAwareness = "Self Awareness",
  SelfManagement = "Self Management",
  SocialAwareness = "Social Awareness",
  RelationalManagement = "Relational Management",
}

export enum QuestionTypes {
  Standard = "standard",
  Company = "company",
}

export enum QuestionInputTypes {
  Rating = "rating",
  Paragraph = "paragraph",
}

export interface QuestionInfo {
  id: number;
  created_at: string | Date;
  updated_at: string | Date;
  company_id: number;
  question_set_type: QuestionTypes;
  last_frozen_by: number;
  frozen_on: string | Date;
  frozen_by: UserInterface;
  unfrozen_by: UserInterface;
  unfrozen_on: Date | string;
}

export interface QuestionResponse {
  id: number;
  paragraph_response: string | null;
  rating_response: null | number;
  user_invitation_id: number;
  question_id: number;
  question: QuestionInterface;
}

export interface ResponseInterFace {
  id: number | null;
  paragraph_response: string | null;
  rating_response: null | number;
}

export interface QuestionResponseFilter {
  all: boolean;
  invitationId: number;
  category: QuestionCategories;
  subcategory: QuestionResponseSubCategories;
  inputType: QuestionInputTypes;
  questionType: QuestionTypes;
}

export enum QuestionResponseSubCategories {
  SetDirection = "Set Direction",
  Align = "Align",
  Motivate = "Motivate",
  Manage = "Manage",
  Discover = "Discover",
  Teach = "Teach",
  Model = "Model",
  Coach = "Coach",
  Know = "Know",
  Connect = "Connect",
  Provide = "Provide",
  Protect = "Protect",
  SelfAwareness = "Self Awareness",
  SelfManagement = "Self Management",
  SocialAwareness = "Social Awareness",
  RelationalManagement = "Relational Management",
  Custom = "Custom",
}
