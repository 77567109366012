import { CompanyInterface } from "./CompanyInterface";

export interface UserInterface {
  id: number;
  created_at: string | Date;
  updated_at: string | Date;
  email: string;
  first_name: string;
  last_name: string;
  roles: RoleInterface[];
  is_editable: boolean;
}

export interface RoleInterface {
  id: number;
  created_at: string | Date;
  updated_at: string | Date;
  role_name: string;
  display_name: string;
}
export interface InvitedUserInterface {
  id: number;
  created_at: Date | string;
  updated_at: Date | string;
  invitation_sent: boolean;
  invitation_type: string;
  invited_user: UserInterface;
  invited_by_user: UserInterface;
  invited_for_user: UserInterface | null;
  rater_type: AllRaterType | null;
  item_count: number;
  company_id: number | null;
  company_employee_id: number | null;
  company: CompanyInterface | null;
  days_left: number | null;
  feedback_completion_count: number;
  meta: {
    id: number;
    created_at: Date | string;
    updated_at: Date | string;
    duration_days: string;
    admin_id: number | null;
    feedback_done: boolean;
    comments_deleted: boolean | null;
    year: string;
    feedback_completion_date: Date | string;
  };
  sent_on: Date | string;
}

export interface UserFilterInterface {
  invitationType: string;
  all: boolean;
  name: string | null;
  invited_user: number | null;
  invited_for: number | null;
  companyId: number | null;
  adminId: number | null;
  allUsers: boolean | null;
  feedback_completed: boolean;
  year: string | number;
}

export enum Role {
  Admin = "admin",
  Participants = "participant",
  Raters = "rater",
}

const directReport = "Direct Report";

export enum RaterType {
  Supervisor = "Supervisor",
  DirectReport = directReport,
  Peer = "Peer",
  Other = "Other",
}

export enum DurationDays {
  Three = "3",
  Fifteen = "15",
  Thirty = "30",
}

export enum AllRaterType {
  Self = "Self",
  Supervisor = "Supervisor",
  DirectReport = directReport,
  Peer = "Peer",
  Other = "Other",
}

export const RaterTypeNames: Record<
  RaterType,
  { value: RaterType; display_name: string }
> = {
  [RaterType.Supervisor]: {
    value: RaterType.Supervisor,
    display_name: "Supervisor",
  },
  [RaterType.DirectReport]: {
    value: RaterType.DirectReport,
    display_name: directReport,
  },
  [RaterType.Peer]: {
    value: RaterType.Peer,
    display_name: "Peer",
  },
  [RaterType.Other]: {
    value: RaterType.Other,
    display_name: "Other",
  },
};

export const AllRaterTypeNames: Record<
  AllRaterType,
  { value: AllRaterType; display_name: string }
> = {
  [AllRaterType.Self]: {
    value: AllRaterType.Self,
    display_name: "Self",
  },
  [AllRaterType.Supervisor]: {
    value: AllRaterType.Supervisor,
    display_name: "Supervisor",
  },
  [AllRaterType.DirectReport]: {
    value: AllRaterType.DirectReport,
    display_name: directReport,
  },
  [AllRaterType.Peer]: {
    value: AllRaterType.Peer,
    display_name: "Peer",
  },
  [AllRaterType.Other]: {
    value: AllRaterType.Other,
    display_name: "Other",
  },
};

export interface InviteUserInterface {
  first_name: string;
  last_name: string;
  email: string;
  invited_for: null | number;
  invitation_type: string;
  rater_type: null | string;
  duration_days: string | null;
  admin_id: number | null;
}

export interface DashboardInterface {
  total_companies: number;
  total_users: number;
  total_active_sessions: number;
}

export interface ParticipantInterface {
  days_left: number;
  feedback_completion_count: number;
}

export enum CurrentRole {
  SuperAdmin = "super_admin",
  Admin = "admin",
  Participant = "participant",
  Rater = "rater",
}
