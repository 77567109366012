<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { appContainer } from "../container";
import router from "../routes";
import { AuthService } from "../services/authService";
import PageHeaderComponent from "./PageHeaderComponent.vue";
import { ToastService } from "../services/toastService";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";
import { UserService } from "../services/userService";
import { LoadingService } from "../services/loadingService";
import { RoleInterface } from "../interface/UserInterface";
import { ActiveYearStore } from "../stores/ActiveYearStore";

const authService = appContainer.resolve(AuthService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);

const questionStatusStore = useIsFrozenStore();
const activeRoleStore = ActiveRoleStore();

const userService = appContainer.resolve(UserService);
const isLoading = ref(true);
const totalRoles = ref(0);
const activeYear = ref<number>(new Date().getFullYear());
const years = ref<number[]>([]);
const activeYearStore = ActiveYearStore();

function isActiveMenuItem(path: string, fullCheck = true): boolean {
  return fullCheck
    ? router.currentRoute.value.fullPath === path
    : router.currentRoute.value.fullPath.startsWith(path);
}
function hideMenu(showToast: boolean = false) {
  showSidebar.value = false;
  if (showToast) {
    toastService.error(
      "Please Add and Freeze Questions to Access This Feature ",
      { duration: 5000, position: "top" },
    );
  }
}

const skipRoutes = ["/login", "/auth-landing, /choose-account"];

function sidebarVisible() {
  return (
    !skipRoutes.includes(router.currentRoute.value.fullPath) &&
    !router.currentRoute.value.fullPath.startsWith("/login")
  );
}
onBeforeMount(async () => {
  activeRoleStore.getActiveRole();
  setupYears();
  await hasMultipleRoles();
  if (activeRoleStore.activeRole?.role_name === "super_admin")
    await questionStatusStore
      .getQuestionStatus()
      .then(() => (isLoading.value = false));
  else isLoading.value = false;
});

function setupYears() {
  const startYear = 2023;

  years.value = Array.from(
    { length: activeYear.value - startYear + 1 },
    (_, i) => startYear + i,
  );
}
const showSidebar = ref(false);

async function logout() {
  const loader = await loadingService.show();
  await authService.logout().finally(() => loader.hide());
}

async function hasMultipleRoles() {
  if (
    authService
      .user()
      ?.roles.some((role: RoleInterface) => role.role_name === "super_admin")
  ) {
    totalRoles.value = 1;
  }
  await userService
    .getInvitedUser({
      invited_user: authService.user()?.id,
      all: true,
      allUsers: true,
    })
    .then((result) => {
      totalRoles.value = totalRoles.value + result.items.length;
    });
}
</script>
<template>
  <button
    v-if="sidebarVisible()"
    class="btn border-0 position-fixed text-white sidebar-close-btn"
    @click="showSidebar = false"
    :class="{ 'd-block': showSidebar }"
  >
    <i class="bi bi-x-lg fs-2"></i>
  </button>
  <div
    class="aside-sidebar h-100 bg-white"
    v-if="sidebarVisible()"
    :class="{ showSidebar }"
  >
    <div class="px-4">
      <router-link
        class="navbar-brand d-flex align-items-center w-100 py-4"
        to="/dashboard"
      >
        <img src="../assets/logo.png" alt="Trusted Leaders Logo" />
      </router-link>
    </div>
    <ul class="navbar-nav" v-if="!isLoading">
      <template v-if="activeRoleStore.activeRole?.role_name != 'rater'">
        <li
          :class="{ active: isActiveMenuItem('/dashboard') }"
          @click="hideMenu(false)"
        >
          <router-link to="/dashboard">
            <i class="bi bi-grid"></i>Dashboard</router-link
          >
        </li></template
      >

      <template v-if="activeRoleStore.activeRole?.role_name === 'super_admin'">
        <li
          :class="{
            active:
              isActiveMenuItem('/manage-questions') ||
              isActiveMenuItem('/preview-feedback'),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/manage-questions">
            <i class="bi bi-list-task"></i>Manage Questions</router-link
          >
        </li>
        <li
          :class="{
            active: isActiveMenuItem('/manage-users', false),
            disabled: !questionStatusStore.isFrozen,
          }"
          @click="hideMenu(!questionStatusStore.isFrozen)"
        >
          <router-link to="/manage-users">
            <i class="bi bi-person-check"></i>Manage Users</router-link
          >
        </li>
        <li
          :class="{
            active:
              isActiveMenuItem('/manage-companies') ||
              isActiveMenuItem('/company', false),
            disabled: !questionStatusStore.isFrozen,
          }"
          @click="hideMenu(!questionStatusStore.isFrozen)"
        >
          <router-link to="/manage-companies">
            <i class="bi bi-buildings"></i>Manage Companies</router-link
          >
        </li>
        <li
          :class="{
            active: isActiveMenuItem('/manage-reports', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/manage-reports">
            <i class="bi bi-file-earmark-spreadsheet"></i>Manage
            Reports</router-link
          >
        </li>
        <li
          :class="{ active: isActiveMenuItem('/app-settings') }"
          @click="hideMenu(false)"
        >
          <router-link to="/app-settings">
            <i class="bi bi-gear"></i>App Settings</router-link
          >
        </li>
      </template>

      <template v-if="activeRoleStore.activeRole?.role_name === 'admin'">
        <li
          :class="{
            active:
              isActiveMenuItem('/manage-participants') ||
              isActiveMenuItem('/participant', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/manage-participants">
            <i class="bi bi-person-plus"></i>Manage Participants</router-link
          >
        </li>
        <li
          :class="{
            active: isActiveMenuItem('/manage-reports', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link
            :to="`/manage-reports/${authService.user()?.id}/participant`"
          >
            <i class="bi bi-file-earmark-spreadsheet"></i>Manage
            Reports</router-link
          >
        </li>
      </template>

      <template v-if="activeRoleStore.activeRole?.role_name === 'participant'">
        <li
          :class="{
            active:
              isActiveMenuItem('/manage-raters') ||
              isActiveMenuItem('/rater', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/manage-raters">
            <i class="bi bi-person-plus"></i>Manage Raters</router-link
          >
        </li>
      </template>
      <template v-if="activeRoleStore.activeRole?.role_name === 'rater'">
        <li
          :class="{
            active: isActiveMenuItem('/instructions', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/instructions">
            <i class="bi bi-list-ul"></i>Instructions</router-link
          >
        </li>
      </template>
      <template v-if="totalRoles > 1">
        <li
          :class="{
            active: isActiveMenuItem('/switch-account', false),
          }"
          @click="hideMenu(false)"
        >
          <router-link to="/switch-account">
            <i class="bi bi-people"></i>Switch Account</router-link
          >
        </li>
      </template>
      <div
        class="date-dropdown"
        v-if="activeRoleStore.activeRole?.role_name === 'super_admin'"
      >
        <select
          class="form-select"
          name="date"
          v-model="activeYear"
          @change="activeYearStore.updateActiveYear(activeYear)"
        >
          <option :value="year" v-for="year in years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <li class="mt-auto mb-4">
        <button
          class="logout-btn btn d-flex align-items-center mx-auto text-primary"
          @click="logout()"
        >
          <i class="bi bi-power"></i>Log Out
        </button>
      </li>
    </ul>
  </div>
  <div
    class="app-page"
    :class="{
      'p-0': !sidebarVisible(),
      'vh-100': router.currentRoute.value.fullPath != '/login',
    }"
  >
    <PageHeaderComponent
      @showSidebar="showSidebar = true"
      v-if="sidebarVisible()"
    />
    <RouterView />
  </div>
</template>

<style scoped lang="scss">
@import "../theme/variables";

.date-dropdown {
  margin-top: 50px;
  padding: 0 20px 0 30px;
}

.sidebar-close-btn {
  display: none;
  top: 10px;
  right: 10px;
  z-index: 20;
}

.app-page {
  flex: 1;
  overflow: auto;
  padding-top: 110px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.aside-sidebar {
  width: 250px;
  border-right: 1px solid #d1d9e2;
  transition: transform 200ms ease;
}
.navbar-brand {
  img {
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    object-fit: contain;
  }
}
ul {
  height: calc(100% - 108px);
  overflow: auto;
  li {
    border-left: 10px solid transparent;
    transition: all ease 200ms;
    a {
      padding: 5px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      user-select: none;
      color: inherit;
      text-decoration: none;
      font-size: 14px;
    }
    &.active {
      color: $blue;
      font-weight: 600;
      background: rgb($blue, 0.15);
      border-color: $blue;
    }
    &.disabled {
      background-color: #d1d9e2;
      opacity: 0.5;
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    }
  }
}
i {
  font-size: 22px;
}
.logout-btn {
  gap: 12px;
  border: none !important;
  cursor: pointer;
  user-select: none;
}

.bi-list-ul {
  &::before {
    font-weight: 600 !important;
  }
}

@media (max-width: 991px) {
  .app-page {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .aside-sidebar {
    position: fixed;
    transform: translateX(-100%);
    z-index: 10;
    &.showSidebar {
      transform: translateX(0);
      &::after {
        content: "";
        position: fixed;
        width: calc(100vw - 249px);
        transform: translateX(100%);
        height: 100%;
        right: 0;
        top: 0;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.662);
      }
    }
  }
}
</style>
