<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { appContainer } from "../container";
import { AuthService } from "../services/authService";
import { LoadingService } from "../services/loadingService";
import { UserService } from "../services/userService";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";
import {
  AllRaterType,
  CurrentRole,
  DashboardInterface,
  InvitedUserInterface,
  Role,
} from "../interface/UserInterface";
import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import StatusCard from "../components/StatusCard.vue";
import router from "../routes";
import { StorageKeys, StorageService } from "../services/storage.service";
import { ActiveYearStore } from "../stores/ActiveYearStore";

const activeRoleStore = ActiveRoleStore();
const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const loaderService = appContainer.resolve(LoadingService);
const storageService = appContainer.resolve(StorageService);

const currentInvitation = ref<InvitedUserInterface | null>();
const dashboardInfo = ref<DashboardInterface>();
const raterCount = ref(0);
const currentInvitationStore = CurrentInvitationStore();
const isLoading = ref(true);
const selfRater = ref<InvitedUserInterface>();

const activeYearStore = ActiveYearStore();

onMounted(async () => {
  const loader = await loaderService.show();
  await initDashboard().then(() => {
    setTimeout(() => {
      loader.hide();
      isLoading.value = false;
    }, 500);
  });
});

async function initDashboard() {
  await activeRoleStore.getActiveRole();

  const roleName = activeRoleStore.activeRole?.role_name;

  if (roleName !== CurrentRole.SuperAdmin) {
    await currentInvitationStore.getCurrentInvitation();

    currentInvitation.value = currentInvitationStore.currentInvitation;
  }

  if (roleName === CurrentRole.Participant) {
    await getRaterCount();
    await checkSelfRated();
  } else if (roleName === CurrentRole.SuperAdmin) {
    await fetchDashboardData(activeYearStore.activeYear);
  }
}
async function fetchDashboardData(year: number) {
  userService
    .getDashboardData(year)
    .then((result) => (dashboardInfo.value = result));
}

watch(
  () => activeYearStore.activeYear,
  () => {
    initDashboard();
  },
);

async function getRaterCount() {
  raterCount.value = (
    await userService.getInvitedUser({
      invitationType: Role.Raters,
      invited_for: authService.user()?.id,
      all: true,
      companyId: currentInvitationStore.currentInvitation?.company_id,
      adminId: currentInvitationStore.currentInvitation?.invited_for_user?.id,
    })
  ).items.length;
}
async function checkSelfRated() {
  selfRater.value = await userService
    .getInvitedUser({
      invited_user: currentInvitation.value?.invited_user.id,
      invitationType: Role.Raters,
      companyId: currentInvitationStore.currentInvitation?.company_id,
    })
    .then((result) =>
      result.items.find((item) => item.rater_type === AllRaterType.Self),
    );
}

async function handleRateYourSelf() {
  const selfRater = await userService
    .getInvitedUser({
      invitationType: Role.Raters,
      invited_for: authService.user()?.id,
      all: true,
      companyId: currentInvitationStore.currentInvitation?.company_id,
    })
    .then((result) => {
      return result.items.find((item) => item.rater_type === AllRaterType.Self);
    });
  await authService.fetchUser();
  const activeRole = authService
    .user()
    ?.roles.filter((role) => role.role_name === Role.Raters)[0];
  await storageService.set(StorageKeys.Current_Invitation, selfRater);

  await storageService.set(StorageKeys.Active_Role, activeRole);
  await currentInvitationStore.getCurrentInvitation();
  await activeRoleStore.getActiveRole();
  router.replace("instructions");
}
</script>

<template>
  <template v-if="!isLoading">
    <div class="d-flex align-items-center d-md-none mb-4">
      <small class="text-body-tertiary me-2">You are signed in as :</small>
      <p class="mb-0">{{ activeRoleStore.activeRole?.display_name }}</p>
    </div>
    <h2 class="mb-4 fw-medium fs-4">Statistics</h2>
    <div class="stats-wrapper d-flex flex-wrap w-100">
      <template v-if="activeRoleStore.activeRole?.role_name === 'super_admin'">
        <StatusCard
          title="Invited Users"
          :value="dashboardInfo!.total_users"
          icon="bi-person"
        />
        <StatusCard
          title="Total Companies"
          :value="dashboardInfo!.total_companies"
          icon="bi-buildings"
        />
        <StatusCard
          title="Active Sessions"
          :value="dashboardInfo!.total_active_sessions"
          icon="bi-file-earmark-spreadsheet"
        />
      </template>

      <template v-if="activeRoleStore.activeRole?.role_name === 'admin'">
        <StatusCard
          title="Total Participants Invited by You"
          :value="currentInvitation!.item_count.toString()"
          icon="bi-person"
        />
      </template>
      <template v-if="activeRoleStore.activeRole?.role_name === 'participant'">
        <StatusCard
          title="Total Raters Invited by You"
          :value="raterCount"
          icon="bi-person"
        />
        <StatusCard
          title="Days Left Before Assessment Ends"
          :value="currentInvitation!.days_left!"
          icon="bi-clock"
        />
        <StatusCard
          title="Assessment Completed by Raters"
          :value="`${currentInvitation!.feedback_completion_count}/${raterCount}`"
          icon="bi-clipboard-check"
        />
      </template>
    </div>
    <template v-if="activeRoleStore.activeRole?.role_name === 'participant'">
      <div class="mt-5" v-if="selfRater && !selfRater.meta.feedback_done">
        You have {{ currentInvitation?.days_left }} days left to rate yourself.
        <button
          class="btn d-block btn-secondary text-white mt-3"
          @click="handleRateYourSelf"
        >
          Rate yourself now
        </button>
      </div>
    </template>
  </template>
</template>

<style lang="scss" scoped>
@import "../theme/variables";
.stats-wrapper {
  gap: 10px;
}
</style>
