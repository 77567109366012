<script setup lang="ts">
import { InvitedUserInterface, Role } from "../interface/UserInterface";

const props = defineProps<{
  user: InvitedUserInterface;
}>();

function getIconName() {
  switch (props.user.invitation_type) {
    case Role.Admin:
      return "bi-gear-fill";
    case Role.Participants:
      return "bi-person-fill";
    case Role.Raters:
      return "bi-star-fill";
    default:
      return "bi-box";
  }
}
</script>
<template>
  <div class="icon-wrapper d-flex align-items-center justify-content-center">
    <i class="bi" :class="getIconName()"></i>
  </div>
</template>
<style lang="scss" scoped>
@import "../theme/variables";

.icon-wrapper {
  background-color: #eaf1fb;
  color: $blue;
  padding: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
}
@media (max-width: 576px) {
  .icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
  }
}
</style>
