<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import UserCardListing from "../manage-users/components/UserListingCard.vue";
import { useRoute } from "vue-router";
import { ActiveYearStore } from "../../stores/ActiveYearStore";
import { watch } from "vue";

const allAdmins = ref<InvitedUserInterface[]>();
const userService = appContainer.resolve(UserService);
const route = useRoute();
const activeYearStore = ActiveYearStore();

onMounted(async () => {
  await getAdminList();
});
async function getAdminList() {
  if (Number(route.params.id)) {
    return userService
      .getInvitedUser({
        invitationType: Role.Admin,
        all: true,
        companyId: Number(route.params.id),
        year: activeYearStore.activeYear,
      })
      .then((result) => {
        allAdmins.value = result.items.filter((admin) => admin.item_count > 0);
      });
  } else {
    return userService
      .getInvitedUser({
        invitationType: Role.Admin,
        all: true,
        year: activeYearStore.activeYear,
      })
      .then((result) => {
        allAdmins.value = result.items.filter(
          (item) => item.invitation_sent === true,
        );
      });
  }
}

watch(
  () => activeYearStore.activeYear,
  () => getAdminList(),
);
</script>
<template>
  <h2 class="fw-medium fs-4 mb-3">Manage Reports</h2>
  <h3 class="fw-medium fs-5 mb-3 ff-montserrat">List of Admins</h3>
  <UserCardListing
    v-for="admin of allAdmins"
    :showDetail="false"
    :user="admin"
    :key="admin.id"
    :forReports="true"
  />
</template>
