import type { NavigationGuardWithThis } from "vue-router";
import { AuthService } from "../services/authService";
import { appContainer } from "../container";

export const isAuthenticatedGuard: NavigationGuardWithThis<
  undefined
> = async () => {
  const authService: AuthService = appContainer.resolve(AuthService);
  await authService.initAuthState();
  // await authService.fetchUser().catch(() => true);
  return authService.user() ? true : { path: "/login" };
};
