<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { PagingInterface } from "../../../interface/PaginationInterface";
import { InvitedUserInterface } from "../../../interface/UserInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { LoadingService } from "../../../services/loadingService";
import { useRoute } from "vue-router";
import PaginationComponent from "../../../components/PaginationComponent.vue";
import AddUserModal from "../../manage-users/components/AddUserModal.vue";
import UserListingCard from "../../manage-users/components/UserListingCard.vue";
import { ActiveYearStore } from "../../../stores/ActiveYearStore";

const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedEmployees = ref<InvitedUserInterface[]>([]);

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const route = useRoute();
const searchField = ref("");
const showInviteEmployeeModal = ref(false);
const isLoading = ref(true);
const activeYearStore = ActiveYearStore();

onBeforeMount(async () => {
  await fetchInvitedEmployees().then(() => (isLoading.value = false));
});

async function fetchInvitedEmployees(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        companyId: Number(route.params.id),
        name: searchField.value,
        year: activeYearStore.activeYear,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedEmployees.value = result.items;
    })
    .finally(() => loader.hide());
}

watch(
  () => activeYearStore.activeYear,
  () => {
    fetchInvitedEmployees();
  },
);

async function sendInvitation() {
  fetchInvitedEmployees();
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  fetchInvitedEmployees({ page: pageNumber, offset: 8 });
}
async function editInvitation() {
  fetchInvitedEmployees();
}

function searchInvitedEmployees() {
  if (searchField.value === "") {
    return;
  }
  fetchInvitedEmployees();
}

watch(searchField, () => {
  if (searchField.value === "") {
    fetchInvitedEmployees();
  }
});

function employeeAdded() {
  showInviteEmployeeModal.value = false;
  fetchInvitedEmployees();
}

function userDeleted() {
  fetchInvitedEmployees();
}
</script>
<template>
  <template v-if="isLoading"></template>
  <template v-else>
    <div
      class="d-flex align-items-center justify-content-between flex-wrap gap-4"
    >
      <form
        class="d-flex justify-content-between search-wrapper align-items-center"
        @submit.prevent="searchInvitedEmployees"
      >
        <input
          type="search"
          class="form-control form-control-sm"
          placeholder="Search"
          v-model="searchField"
        /><button
          type="submit"
          class="text-white btn bg-primary flex-center btn-sm"
        >
          Search
        </button>
      </form>
      <button
        class="btn btn-primary btn-sm text-white ms-auto"
        @click="showInviteEmployeeModal = true"
      >
        Add Employee
      </button>
    </div>

    <div class="employees-list pt-4" v-if="invitedEmployees.length">
      <UserListingCard
        v-for="(employee, index) in invitedEmployees"
        :key="index"
        :user="employee"
        @invite="sendInvitation"
        @edit="editInvitation"
        @delete="userDeleted"
        :companyId="Number(route.params.id)"
        :showDetail="false"
      />
    </div>
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
      v-else
    >
      No Record Found
    </div>
    <div class="pagination-wrapper" v-if="paginatedData?.totalItems">
      <PaginationComponent
        v-if="paginatedData.totalItems > paginatedData.offset"
        :totalRows="paginatedData!.totalItems"
        :currentPage="paginatedData?.page"
        :perPage="paginatedData?.offset"
        @pageChange="updatePage"
      />
    </div>

    <AddUserModal
      v-if="showInviteEmployeeModal"
      :companyId="Number(route.params.id)"
      @close="showInviteEmployeeModal = false"
      @add="employeeAdded"
    />
  </template>
</template>

<style lang="scss" scoped>
.search-wrapper {
  max-width: 350px;
  gap: 8px;
}
.employees-list {
  min-height: calc(100% - 418px);
}
</style>
