<script lang="ts" setup>
import { appContainer } from "../container";
import { AllRaterType, Role } from "../interface/UserInterface";
import { AuthService } from "../services/authService";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";
import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";

const authService = appContainer.resolve(AuthService);
const emit = defineEmits(["showSidebar"]);
const props = defineProps<{ fullwidth?: boolean }>();
const activeRoleStore = ActiveRoleStore();
const currentInvitationStore = CurrentInvitationStore();

function getParticipantName() {
  if (
    currentInvitationStore.currentInvitation?.rater_type === AllRaterType.Self
  ) {
    return "for: Self";
  }
  return `for:
    ${currentInvitationStore.currentInvitation?.invited_for_user?.first_name} ${currentInvitationStore.currentInvitation?.invited_for_user?.last_name}`;
}
</script>
<template>
  <div
    class="page-header py-3 px-4 d-flex align-items-center fw-medium bg-white"
    :class="{ 'w-100': props.fullwidth }"
  >
    <button
      v-if="!fullwidth"
      class="btn border-0 p-0 hamburger-btn d-lg-none d-block"
      @click="emit('showSidebar')"
    >
      <i class="bi bi-list"></i>
    </button>
    <div
      class="role d-none d-md-block"
      v-if="
        activeRoleStore.activeRole?.role_name === 'super_admin' ||
        (activeRoleStore.activeRole && currentInvitationStore.currentInvitation)
      "
    >
      <small class="text-body-tertiary">You are signed in as :</small>
      <p class="mb-0">
        {{ activeRoleStore.activeRole?.display_name }}

        {{
          activeRoleStore.activeRole?.role_name === Role.Raters
            ? getParticipantName()
            : ""
        }}
      </p>
    </div>
    <div class="profile-btn ms-auto d-flex flex-column align-items-end">
      <button
        class="btn text-capitalize pe-0 text-primary"
        @click="$router.push('/profile')"
      >
        {{ authService.user()?.first_name }} {{ authService.user()?.last_name }}
      </button>
      <small class="text-body-tertiary">{{ authService.user()?.email }}</small>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page-header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 250px);
  border-bottom: 1px solid #d1d9e2;
  z-index: 9;
  button {
    border: none !important;
  }
  .hamburger-btn {
    height: 40px;
    width: 40px;
    font-size: 30px;
    font-weight: 700;
  }
}
.role {
  p {
    font-size: 14px;
  }
  small {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .page-header {
    width: 100%;
  }
}
</style>
