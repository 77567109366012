import { defineStore } from "pinia";
import { ref } from "vue";

export const ActiveYearStore = defineStore("activeYear", () => {
  const activeYear = ref(new Date().getFullYear());

  function updateActiveYear(year: number) {
    activeYear.value = year;
  }
  return {
    activeYear,
    updateActiveYear,
  };
});
