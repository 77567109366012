<template>
  <div class="w-100">
    <header>
      <nav class="navbar navbar-expand-lg bg-body-tertiary py-3">
        <div class="container-fluid custom-container">
          <a class="navbar-brand p-0" href="#"
            ><img src="../assets/logo.png" alt=""
          /></a>
          <div class="navbar-nav ms-auto">
            <a
              class="nav-link d-flex align-items-center px-3"
              aria-current="page"
              href="mailto:admin@trustedleader360.com"
              ><i class="bi bi-telephone-fill d-lg-none fs-5"></i
              ><span class="d-none d-lg-block text-primary">
                Contact us
              </span></a
            >
          </div>
        </div>
      </nav>
    </header>
    <div class="container-lg d-flex align-items-center justify-content-center">
      <div class="d-flex w-100 h-100 pb-4 justify-content-center">
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h1 class="mb-4 fw-bold text-center">
            Welcome to Trusted Leader 360°
          </h1>
          <p class="mb-4 mb-md-5">
            The following Trusted Leader 360° Assessment measures feedback in
            Five Critical Areas. Every leader is unique. Each leader has their
            own distinct context and experiences. Engaging in the TL360° enables
            you to gain personalized, creating a pathway for greater leadership
            effectiveness and therefore greater joy.
          </p>
          <a
            :href="oauthUrl"
            class="text-white btn btn-primary mx-auto w-50 py-2"
            >Let's Get Started</a
          >
        </div>
      </div>
    </div>

    <footer class="p-3 p-lg-4 bg-primary text-white flex-wrap">
      <div class="container-fluid custom-container d-flex align-items-center">
        <small>Copyright &copy; Trusted Leaders 2024</small>
        <small>All rights reserved</small>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { appContainer } from "../container";
import getPkce from "oauth-pkce";
import { ApiCallService } from "../services/apiCall.service";
import { onBeforeMount, ref } from "vue";
import { StorageKeys, StorageService } from "../services/storage.service";

const apiCallService = appContainer.resolve(ApiCallService);
const storageService = appContainer.resolve(StorageService);

const oauthUrl = ref("");

async function initializePkce() {
  return new Promise<{ verifier: string; challenge: string }>(
    (resolve, reject) => {
      getPkce(
        Number(import.meta.env.VITE_CODE_VERIFIER_LENGTH),
        (error, { verifier, challenge }) => {
          if (error) {
            reject(error);
          } else {
            resolve({ verifier, challenge });
          }
        },
      );
    },
  );
}

onBeforeMount(async () => {
  await initializePkce().then(({ challenge, verifier }) => {
    oauthUrl.value = apiCallService.url(
      `oauth/authorization?grant_type=pkce&client_id=${import.meta.env.VITE_CLIENT_ID}&code_challenge=${challenge}&algorithm=sha256&redirect_url=${import.meta.env.VITE_APP_URL}/${import.meta.env.VITE_REDIRECT_URL}`,
    );
    storageService.set(StorageKeys.CODE_CHALLENGE, challenge);
    storageService.set(StorageKeys.CODE_VERIFIER, verifier);
  });
});
</script>

<style lang="scss" scoped>
.custom-container {
  max-width: 1480px;
}

.main {
  overflow: auto;
}

.navbar-brand {
  height: 50px;
  max-width: 150px;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    margin: auto;
    text-align: center;
  }
}

.container-lg {
  min-height: calc(100vh - 151px);
}
.no-underline {
  text-decoration: none;
}

.login-form,
.password-form {
  max-width: 390px;
}

footer .custom-container {
  justify-content: space-between;
  gap: 10px;
}

a {
  font-size: 18px;
}

ul li a {
  padding: 10px;
}
@media (max-width: 991px) {
  .container-lg {
    min-height: calc(100vh - 176px);
    a {
      width: 100% !important;
    }
  }

  footer .custom-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>
