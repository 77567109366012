<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { useRoute } from "vue-router";
import UserListingCard from "../manage-users/components/UserListingCard.vue";
import { ActiveYearStore } from "../../stores/ActiveYearStore";

const userService = appContainer.resolve(UserService);

onMounted(async () => {
  await getCompanyParticipant();
});

const route = useRoute();

const participants = ref<InvitedUserInterface[]>();
const activeYearStore = ActiveYearStore();

async function getCompanyParticipant() {
  await userService
    .getInvitedUser({
      invitationType: Role.Participants,
      companyId: Number(route.params.id),
      feedback_completed: true,
      all: true,
      year: activeYearStore.activeYear,
    })
    .then((result) => (participants.value = result.items));
}

watch(
  () => activeYearStore.activeYear,
  () => {
    getCompanyParticipant();
  },
);
</script>
<template>
  <p class="fw-medium fs-5">Participants Listing</p>
  <UserListingCard
    v-for="participant of participants"
    :showDetail="false"
    :user="participant"
    :key="participant.id"
    :forReports="true"
  />
</template>
